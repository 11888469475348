import { Component, OnInit, inject } from '@angular/core';
import { User } from 'src/app/01-models';
import { AuthService, TicketService } from 'src/app/02-services';
import { TranslateModule } from '@ngx-translate/core';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { addIcons } from 'ionicons';
import { importIcons } from 'src/app/05-shared/utils/ImportIcons';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.page.html',
    styleUrls: ['./tabs.page.scss'],
    imports: [...importsStandalone, TranslateModule]
})
export class TabsPage implements OnInit {
  authService = inject(AuthService);
  ticketService = inject(TicketService);

  user: User;
  todoTicketsTotal: number = 0;

  constructor(
  ) {
    addIcons(importIcons);
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    /*this.ticketService
      .getAllTickets()
      .then((tickets) => (this.todoTicketsTotal = tickets.length));*/
  }
}
