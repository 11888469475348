<ion-tabs>
  <ion-tab-bar slot="bottom">
    @if (authService.hasPermission('FOOTER.BUTTON.01-PRESENCIAS')) {
    <ion-tab-button tab="presence-list" data-cy="tab-button-presence-list" data-cy-permission="FOOTER.BUTTON.01-PRESENCIAS">
      <ion-icon name="time"></ion-icon>
      <ion-label>{{ 'TABS.PRESENCES' | translate }}</ion-label>
    </ion-tab-button>
    }
    @if (authService.hasPermission('FOOTER.BUTTON.02-SERVICIOSV')) {
    <ion-tab-button tab="service-sv" data-cy="tab-button-service-sv" data-cy-permission="FOOTER.BUTTON.02-SERVICIOSV">
      <ion-icon name="calendar"></ion-icon>
      <ion-label>{{ 'TABS.SERVICES_SV' | translate }}</ion-label>
    </ion-tab-button>
    }
    @if (authService.hasPermission('FOOTER.BUTTON.03-TICKETS')) {
      <ion-tab-button tab="ticket-list" data-cy="tab-button-ticket-list" data-cy-permission="FOOTER.BUTTON.03-TICKETS">
        <ion-icon name="warning"></ion-icon>
        <ion-label>Tickets</ion-label>
      </ion-tab-button>
    }
    
  </ion-tab-bar>
</ion-tabs>
